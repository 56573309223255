import Constants from "~/helpers/enums/Constants";

const initialState = {
	isAuthenticated: false,
	user: {
		name: "",
		logo: "",
		colors: {
			topBarColor: "#6EC8AF",
			colorPanels: "#6AE5FF",
			colorBarPanels: "#6AE5FF",
		},
	},
	fusoHorario: null,
	infos: {},
};

function authReducer(state = initialState, action = null) {
	switch (action.type) {
		case Constants.LOGIN:
			return {
				...state,
				...action.payload,
				isAuthenticated: true,
			};
		case Constants.LOGOUT:
			return initialState;
		default:
			return state;
	}
}

export default authReducer;
