import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import tutorialAction from "~/actions/tutorialAction";
import VehicleRenewalModal from "~/components/common/vehicleRenewalModal/VehicleRenewalModal.jsx";
import CardTutorial from "./cardTutorial/CardTutorial";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CardNews from "./cardNews/CardNews";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

import Labels from "~/helpers/enums/Labels";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    radioStyle: {
        margin: "auto",
        padding: 0,
        background: "rgba(255, 255, 255, 0.3)",
        borderRadius: "40px",
        width: "70px",
        textAlign: "center",
        display: "flex!important",
        "& li": {
            listStyle: "none",
            cursor: "pointer",
            margin: "0 3px",
            borderRadius: "10px",
            padding: "0",
        },
        "& li.slick-active button": {
            backgroundColor: theme.palette.primary.main,
        },
        "& li button": {
            border: "none",
            background: "#afc3d2",
            color: "transparent",
            cursor: "pointer",
            display: "block",
            height: "15px",
            width: "15px",
            borderRadius: "7.5px",
            "&:focus": {
                background: theme.palette.primary.main,
                outline: "0",
            },
        },
    },
    colorSettings: {
        backgroundColor: theme.palette.primary.main,
        outline: "0",
    },
    showAll: {
        fontWeight: "bold",
        cursor: "pointer",
        float: "right",
        border: "none",
        paddingBottom: "5px",
        color: theme.palette.primary.main,
    },
}));

const Tutorial = (props) => {
    const { t } = useTranslation();
	const location = useLocation();
    const [showAll, setShowAll] = useState(false);
    const dispatch = useDispatch();
    const { tutorial, tutorialFilter } = useSelector((state) => state.tutorialReducer);
    const { canRenew, items } = useSelector((state) => state.renewalReducer);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        dotsClass: classes.radioStyle,
    };
	const notShowPopup = location.state?.notShowPopup
    useEffect(() => {
        dispatch(tutorialAction.tutorialGetData());
    }, [dispatch]);

    useEffect(() => {
        if (canRenew && !notShowPopup) {
            setOpen(canRenew);
        }
    }, [canRenew]);
    const handleChange = (event) => {
        setShowAll(true);
        dispatch(tutorialAction.updateTutorialDataFilter(tutorial, event.target.value));
    };

    return (
        <>
            <div className="slider">
                <CardNews />
                <VehicleRenewalModal
                    vehicles={items}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
                <div className="container-search">
                    <div className="under-line">
                        <Typography variant="h2" style={{ fontSize: "24px", fontWeight: "bold" }}>
                            {t(Labels.TUTORIAL_TIPS)}
                        </Typography>
                    </div>

                    <div>
                        <form>
                            <input placeholder="Pesquisar dicas" onChange={(e) => handleChange(e)} />
                            <SearchIcon className="search-icon" />
                        </form>
                    </div>
                </div>
                {showAll ? (
                    <>
                        {tutorialFilter ? (
                            <>
                                <Grid container spacing={1}>
                                    {" "}
                                    {tutorialFilter.map((dicas) => (
                                        <Grid item xs={4}>
                                            <CardTutorial
                                                title={dicas.titulo}
                                                description={dicas.descricao}
                                                imgUrl={dicas.urlImagem}
                                                urlDica={dicas.urlDica}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <button className={classes.showAll} onClick={() => setShowAll(false)}>
                                    {t(Labels.TUTORIAL_SHOWLESS)}{" "}
                                    <ExpandLessIcon style={{ position: "relative", top: "2px" }} />
                                </button>
                            </>
                        ) : (
                            <>
                                <Grid container spacing={1}>
                                    {" "}
                                    {tutorial.map((dicas) => (
                                        <Grid item xs={4}>
                                            <CardTutorial
                                                title={dicas.titulo}
                                                description={dicas.descricao}
                                                imgUrl={dicas.urlImagem}
                                                urlDica={dicas.urlDica}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <button className={classes.showAll} onClick={() => setShowAll(false)}>
                                    {t(Labels.TUTORIAL_SHOWLESS)}
                                    <ExpandLessIcon style={{ position: "relative", top: "2px" }} />
                                </button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div>
                            <Slider {...settings}>
                                {tutorial.map((dicas) => (
                                    <CardTutorial
                                        title={dicas.titulo}
                                        description={dicas.descricao}
                                        imgUrl={dicas.urlImagem}
                                        urlDica={dicas.urlDica}
                                    />
                                ))}
                            </Slider>
                        </div>
                        <button className={classes.showAll} onClick={() => setShowAll(true)}>
                            {t(Labels.TUTORIAL_SHOWMORE)}{" "}
                            <ExpandMoreIcon style={{ position: "relative", top: "2px" }} />
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

export default Tutorial;
