import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link,
    Typography,
    IconButton,
    Box,
} from "@material-ui/core";
import { CloseIcon } from "~/components/common/icons/Icons";
import { useHistory } from "react-router-dom";
const VehicleRenewalModal = ({ vehicles, open, onClose }) => {
    let history = useHistory();
    return (
        <div>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle style={{ paddingBottom: 0, position: "relative" }}>
                    <Typography variant="h5" align="center" style={{ fontStyle: "bold", width: "100%", fontSize:16 }}>
                        Renovação de Serviços
                    </Typography>
                    <IconButton aria-label="close" onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: 16 }}>
                    <Box
                        style={{
                            overflowY: "auto",
                            width: "70%",
                            margin: "0 auto",
                        }}>
                        <Typography
                            style={{ fontStyle: "bold", fontSize: 14, fontWeight: 600 }}
                            fontStyle="bold"
                            align="center">
                            Prezado(a), o período de 12 (doze) meses de gratuidade dos serviços My Uconnect Fleet para
                            os veículos abaixo está chegando ao fim. Para mais informações sobre a contratação da
                            renovação e continuar utilizando os serviços{" "}
                            <Link
                                onClick={() => {
                                    history.push(`vehicleRenewal`);
                                }}>
                                clique aqui
                            </Link>
                            , ou se preferir, acesse o menu 'Renovação'.
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            maxHeight: "150px",
                            overflowY: "auto",
                            width: "70%",
                            margin: "0 auto",
                        }}>
                        <Table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{ fontWeight: "bold", fontSize: 14, borderBottom: "1px solid #ddd" }}>
                                        Placa
                                    </TableCell>
                                    <TableCell
                                        style={{ fontWeight: "bold", fontSize: 14, borderBottom: "1px solid #ddd" }}>
                                        Chassi
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vehicles[0]?.map((vehicle, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ borderBottom: "1px solid #ddd", fontSize: 14 }}>
                                            {vehicle.Placa ? vehicle.Placa : "-"}
                                        </TableCell>
                                        <TableCell style={{ borderBottom: "1px solid #ddd", fontSize: 14 }}>
                                            {vehicle.Chassi}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default VehicleRenewalModal;
